<template>
  <RevListItemStatic
    :key="moneyOut.id"
    class="rounded-inherit px-24 hover:bg-static-default-min-hover"
  >
    <template #prefix>
      <MyOtherThingTag
        class="mt-6 inline-block"
        :money-out="moneyOut"
        type="badge"
      />
    </template>
    <template #label>
      <div class="flex items-center">
        {{ label }}
      </div>
    </template>
    <template #description>
      <div class="flex">
        {{ description }}
      </div>
    </template>
    <template #suffix>
      <div><MyOtherThingTag class="ml-auto" :money-out="moneyOut" /></div>

      <MyOtherThingPsp :money-out="moneyOut" />
    </template>
  </RevListItemStatic>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { moneyOutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevListItemStatic } from '@ds/components/ListItemStatic'

import translation from './MyOtherThingsListItem.translations'
import MyOtherThingPsp from './components/MyOtherThingPsp/MyOtherThingPsp.vue'
import MyOtherThingTag from './components/MyOtherThingTag/MyOtherThingTag.vue'

type Props = {
  moneyOut: moneyOutAPI.MoneyOut
}

const props = defineProps<Props>()

const i18n = useI18n()

const label = computed(
  () =>
    `${i18n(translation.transferInfo, { amount: i18n.price(props.moneyOut.amount) })}`,
)

const description = computed(() =>
  i18n.date(new Date(props.moneyOut.dateCreation), {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }),
)
</script>
