<template>
  <RevModal
    :name="MODAL_NAMES.MONEYOUT_REDIRECT"
    :title="i18n(translations.title)"
    variant="confirmation"
  >
    <template #body>
      {{ i18n(translations.content) }}

      <RevInfoBlock
        v-if="error"
        class="mt-20"
        :content="'Please give us a sec and then give it another go.'"
        :icon="IconWarning"
        :title="'Something went wrong'"
        variant="danger"
      />
    </template>
    <template #footer>
      <div class="flex">
        <RevButton
          class="mr-auto"
          :size="SizesEnum.Small"
          :variant="VariantsEnum.Secondary"
          @click="closeModal(MODAL_NAMES.MONEYOUT_REDIRECT)"
        >
          {{ i18n(translations.cancelAction) }}
        </RevButton>
        <RevButton
          :disabled="error"
          :loading="loading"
          :size="SizesEnum.Small"
          :to="redirectLink"
          :variant="VariantsEnum.Primary"
        >
          {{ i18n(translations.confirmAction) }}
        </RevButton>
      </div>
    </template>
  </RevModal>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { moneyOutAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { SizesEnum, VariantsEnum } from '@ds/components/Button/Button.constant'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevModal } from '@ds/components/Modal'
import { closeModal, onModalUpdate } from '@ds/components/ModalBase'
import { IconWarning } from '@ds/icons/IconWarning'

import { MODAL_NAMES } from '../../MoneyoutAction.constant'

import translations from './MoneyoutModalRedirect.translations'

const i18n = useI18n()

const error = ref(false)
const loading = ref(false)
const redirectLink = ref('')

async function requestMoneyout() {
  try {
    loading.value = true
    const { payportalLink } = await $httpFetch(moneyOutAPI.postMoneyout)

    redirectLink.value = payportalLink
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
}

onModalUpdate(MODAL_NAMES.MONEYOUT_REDIRECT, ({ opened }) => {
  if (opened && (!redirectLink.value || error.value)) {
    loading.value = false
    error.value = false
    redirectLink.value = ''

    requestMoneyout()
  }
})
</script>
