<template>
  <RevCard>
    <RevList class="rounded-lg" :has-external-borders="false">
      <template v-if="pending">
        <RevListItemStatic v-for="i in 3" :key="i" class="px-16">
          <template #label>
            <RevSkeleton height="1lh" shape="rectangle" />
          </template>
        </RevListItemStatic>
      </template>
      <template v-else-if="moneyouts.length">
        <MyOtherThingsListItem
          v-for="moneyOut in moneyouts"
          :key="moneyOut.id"
          :money-out="moneyOut"
        />
      </template>
      <RevListItemStatic v-else>
        <template #label>
          <span class="ml-32">{{ i18n(translations.noData) }}</span>
        </template>
      </RevListItemStatic>
    </RevList>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { moneyOutAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './MyOtherThingsList.translations'
import MyOtherThingsListItem from './components/MyOtherThingsListItem/MyOtherThingsListItem.vue'

const i18n = useI18n()

const { data, pending } = useHttpFetch(moneyOutAPI.getMoneyouts)

const moneyouts = computed(() =>
  [...(data.value ?? [])].sort(
    (a, b) => Date.parse(b.dateCreation) - Date.parse(a.dateCreation),
  ),
)
</script>
